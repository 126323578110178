.hero {
  overflow: hidden;
}

.hero__image {
  object-fit: cover;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.hero__content *:not(.fmc-button) {
  color: inherit;
}

.hero__content {
  opacity: var(--hero-copy-scrim-opacity);
}

.hero.fmc-billboard .fmc-billboard__scrim {
  --scrim-background: linear-gradient(
    to bottom,
    rgba(var(--scrim-color), 0) 50%,
    rgba(var(--scrim-color), calc(0.60 * var(--hero-copy-scrim-opacity))) 72%,
    rgba(var(--scrim-color), var(--hero-copy-scrim-opacity)));
}
