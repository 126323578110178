@keyframes highlight-shrink {
  from {
    height: var(--highlight-height-start);
  }

  to {
    height: var(--highlight-height-end);
  }
}

@keyframes highlight {
  from {
    width: var(--highlight-width-start);
    border-radius: 0;
    background-size: 800%;
    background-position: 5% 50%;
  }

  30% {
    border-radius: 12px;
  }

  75% {
    width: var(--highlight-width-end);
    background-size: 130%;
  }

  to {
    width: var(--highlight-width-end); /* this is necessary (don't know why) */
    border-radius: 12px;
    background-size: 100%;
    background-position: 0 50%;
  }
}

@keyframes mainImg {
  from {
    transform: scale(0.8) translate(-20%, 0);
  }
  60% {
    transform: scale(0.8) translate(-15%, 0);
  }
  to {
    transform: scale(0.8) translate(-10%, 0);
  }
}

@keyframes mainImgFade {
  from {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }
}

.doubleImage {
  --margin-top: 64px;
  --margin-lr: 20px;
  --image-overlap: -8px;
  --highlight-width-start: 100vw;
  --highlight-height-start: 100vh;
  --highlight-aspect: 2 / 3;
  --highlight-width-end: calc(var(--highlight-width-start) - var(--margin-lr) * 2);
  --highlight-height-end: calc(var(--highlight-width-end) * var(--highlight-aspect));
  --main-width: 100vw;
  --main-height: 100vw;

  display: grid;
  grid: "single-area" calc(var(--highlight-height-start) + var(--margin-top)/2);
  justify-items: center;
  position: relative;
  z-index: 0;
}

.highlightImg, .mainImg, .mainImg img {
  animation-play-state: paused;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-delay: calc(var(--scroll-factor) * -1s);
}

.highlightImg {
  grid-area: single-area;
  position: sticky;
  top: 50vh;
  z-index: 10;
  box-shadow: var(--fmc-elevation__box-shadow--layer2);
  animation-name: highlight, highlight-shrink;
  animation-delay: calc(var(--scroll-factor) * -1s), calc(var(--highlight-shrink-progress) * -1s);
  animation-timing-function: linear, linear;
}

.mainImg {
  grid-area: single-area;
  position: sticky;
  top: 0;
  z-index: 5;
  width: var(--main-width);
  aspect-ratio: 1;
  overflow: hidden;
  animation-name: mainImgFade;
  animation-delay: calc(var(--highlight-shrink-progress) * -1s);
  animation-timing-function: linear;
}

.mainImg img {
  height: var(--main-height);
  transform-origin: top left;
  animation-name: mainImg;
  animation-timing-function: ease-in-out;
}
