.detailContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}

.detail h1,
.detail h2,
.detail h3,
.detail h4,
.detail h5 {
  text-decoration: underline;
}

.detail h5 {
  line-height: 1.5;
  font-weight: 700;
  font-size: clamp(2rem, 6vw, 3.2rem);
}

.detail * {
  font-size: clamp(1.6rem, 3.5vw, 2rem);
}

/****************************
 * Animation settings below *
 */

.detailContainer {
  opacity: 0;
}

.detailContainer.inView {
  opacity: 1;
  transition: all 1s ease 0.5s;
}

.detail {
  opacity: 0;
  transform: translateY(4rem);
}

.inView .detail{
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease var(--transition-delay);
}

.detail p {
  transform: translateY(8rem);
}

.inView .detail p{
  transform: translateY(0);
  transition: all 1s ease var(--transition-delay);
}
