.textSection {
  padding-top: 32px;
  padding-bottom: 32px;
}

.textSection h5 {
  font-size: 24px;
  line-height: 28px;
}

.textSection h4 {
  font-size: 28px;
  line-height: 32px;
}

.textSection h3 {
  font-size: 32px;
  line-height: 36px;
}

.textSection p {
  margin-top: 16px;
}

.textSection button {
  margin-top: 32px;
}

.textSection h3,
.textSection h4,
.textSection h5,
.textSection p {
  opacity: 0;
  transform: translateY(4rem);
}

.textSection button {
  opacity: 0;
}

.inView h3,
.inView h4,
.inView h5 {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease 0.5s;
}

.inView p {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease 1.25s;
}

.inView button {
  opacity: 1;
  transition: all 4s ease 1.25s;
}
