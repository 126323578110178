.header {
  display: grid;
  grid: "left   center   right"  4rem
        /  1fr  auto  1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.headerLogo {
  grid-area: center;
  width: 84px;
}

.menuIcon {
  grid-area: right;
  justify-self: right;
  height: 4rem;
}
