.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.lightning-demo-carousel {
  margin-top: 16px;
  overflow: hidden;
}

.lightning-demo-carousel .tns-controls,
.lightning-demo-carousel .tns-nav,
.lightning-demo-carousel .tns-liveregion {
  display: none;
}

.lightning-demo-carousel ul {
  list-style-type: none;
}

.lightning-demo-carousel li.tns-item {
  display: flex;
  justify-content: center;
}

.lightning-demo-carousel .fmc-carousel {
  touch-action: pan-x;
}

.lightning-demo-carousel .fmc-carousel-indicator {
  margin: 48px 0;
}

.lightning-demo-carousel .fmc-billboard {
  height: 528px;
  width: 320px;
  margin: 0 12px;
}

.lightning-demo-carousel .fmc-billboard .fmc-billboard__scrim {
  --scrim-background: linear-gradient(
    to top,
    rgba(var(--scrim-color), 0) 50%,
    rgba(var(--scrim-color), 0.85) 75%,
    rgba(var(--scrim-color), 1));
}

.lightning-demo-carousel .fmc-billboard__content {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lightning-demo-carousel .fmc-billboard__content p,
.lightning-demo-carousel .fmc-billboard__content h1,
.lightning-demo-carousel .fmc-billboard__content h2,
.lightning-demo-carousel .fmc-billboard__content h3,
.lightning-demo-carousel .fmc-billboard__content h4,
.lightning-demo-carousel .fmc-billboard__content h5 {
  color: var(--fds-color--white);
  text-align: center;
  margin: 12px 0 0;
}

.lightning-demo-carousel .fmc-billboard__content h3 {
  line-height: 36px;
}

.lightning-demo-carousel .fmc-billboard__content button {
  margin: auto 0 24px;
}

.lightning-demo-carousel {
  opacity: 0;
  transform: translateX(320px);
  transition: all 1s ease 0.5s;
}

.lightning-demo-carousel.carousel-in-view {
  opacity: 1;
  transform: translateX(0);
}
