.turntable {
  max-height: 50vh;
  max-width: 100vw;
  width: 100%;
  height: 240px;
  overflow: clip;
  /*   aspect-ratio: 20/9; */
  /*   border: 3px dotted greenyellow; */
}

.turntable img,
.turntable canvas {
  object-fit: cover;
  object-position: center top;
  width: 100%;
  height: 100%;
}
