@keyframes imageSlide {
    from {
        background-position: left 0% top 70%;
        background-size: 300%;
    }

    to {
        background-position: left 40% top 80%;
        background-size: 150%;
    }
}

.singleImage {
    aspect-ratio: 1/1;
    background-size: cover;
    animation: imageSlide 3s ease-in-out forwards;
}
